import {QueryClient, QueryClientProvider} from 'react-query'
import axios from 'axios'
import {Completion} from './completion'
import {AboutModal, ContactModal} from "./modals";
import {useEffect, useState} from "react";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-KZ8DQHV'
}

const BASE_URL = "https://api.talktoneo.com/generate"

TagManager.initialize(tagManagerArgs)


const queryClient = new QueryClient()

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Example/>
        </QueryClientProvider>
    )
}

function Example() {
    const [data, setData] = useState({completions: []});
    const [query, setQuery] = useState('The Funny Thing About Cats is ');
    const [max_len, setMaxLen] = useState(50);
    const [top_k, setTopK] = useState(20);
    const [top_p, setTopP] = useState(0.95)
    const [completions, setCompletions] = useState(3)
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [request, setRequest] = useState(false)
    const [errorCode, setErrorCode] = useState(200)
    const [isDefault, setIsDefault] = useState(true)

    function handleKeyPress(e) {
        if (e.key === 'Enter' && e.shiftKey) {
            fetchData()
        }
    }

    const headers = {'Content-Type': 'text/plain'}

    // useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsDefault(false)
            setIsLoading(true);
            try {
                const result = await axios.post(BASE_URL, {
                    "sentence": query,
                    "n_completions": completions,
                    "top_k": top_k,
                    "top_p": top_p,
                    "max_len": max_len
                }, {headers});
                setData(result.data);
            } catch (error) {
                console.log(JSON.stringify(error))
                setIsError(true);
                setRequest(false);
                setErrorCode(35);
            }
            setIsLoading(false);
            setRequest(false);
        };
    //     fetchData();
    // }, [request]);


    return (


        <body>
        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
              <span className="text-xl inline-block mr-5 align-middle">
                <i className="fas fa-bell"/>
              </span>
            <span className="inline-block align-middle mr-8">
                <b className="capitalize">Alert!</b> This is generating slowly at the moment..
              </span>
        </div>
        <div class="min-h-screen min-w-full bg-gray-100 flex flex-col  p-10">
            <div class="relative w-full max-w-full lg:max-w-6xl xl:max-w-screen-2xl mx-auto">
                <div
                    class="absolute inset-0 -mr-3.5 bg-gradient-to-r to-green-100 from-purple-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:rotate-3 sm:rounded-3xl"></div>
                <div class="relative bg-white shadow-lg sm:rounded-3xl">

                    <div class="flex items-center justify-start pt-6 pl-6">
                        <span class="w-3 h-3 bg-red-400 rounded-full mr-2"></span>
                        <span class="w-3 h-3 bg-yellow-400 rounded-full mr-2"></span>
                        <span class="w-3 h-3 bg-green-400 rounded-full mr-2"></span>
                    </div>

                    <div class="md:px-20 sm:px-5 py-6">


                        <div class="flex items-center justify-between">
                            <div class="flex items-center justify-center">
                                <div class="flex items-center justify-center text-3xl font-bold text-true-gray-800">
                                    <svg class="w-21 h-10 mr-1 text-black-700" fill="none" stroke="currentColor"
                                         viewBox="0 -1 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.0184554,12.9754384 C10.6510955,11.7719065 4.49333538,11.8281403 1.32561077,12.361913 C0.943093201,12.4263686 0.883046134,14.1847483 1.22323817,
                            14.2462718 C1.84933083,14.4994727 2.11644713,15.1313222 2.11644713,15.1313222
                            C2.11644713,15.1313222 2.30339395,15.6919912 2.84630809,17.9721018 C3.38922221,20.2522125 4.68352178,20.5256229 4.68352178,
                            20.5256229 C4.68352178,20.5256229 6.2676914,20.9176315 8.09512239,20.9176314 C9.92255338,20.9176314 11.0157186,20.8345422 12.1860571,
                            20.2491472 C13.3563956,19.6637523 13.8679128,18.0334083 13.8679128,18.0334083 C13.8679128,18.0334083 14.4575765,16.6285553 14.8635328,
                            15.3029802 C14.9908358,14.8872958 16.9280921,14.8872958 17.0553951,15.3029802 C17.4613515,16.6285553 18.0510151,18.0334083 18.0510151,
                            18.0334083 C18.0510151,18.0334083 18.5625324,19.6637523 19.7328708,20.2491472 C20.9032093,20.8345422 21.9963746,20.9176314 23.8238055,
                            20.9176314 C25.6512365,20.9176315 27.2354061,20.5256229 27.2354061,20.5256229 C27.2354061,20.5256229 28.5297057,20.2522125 29.0726198,
                            17.9721018 C29.615534,15.6919912 29.8024808,15.1313222 29.8024808,15.1313222 C29.8024808,15.1313222 30.0695971,14.4994727 30.6956898,
                            14.2462718 C31.0358818,14.1847483 30.9758347,12.4263686 30.5933172,12.361913 C27.4255925,11.8281403 21.2678325,11.7719065 16.9004725,
                            12.9754384 C16.6040978,13.0433095 15.3148302,13.0433095 15.0184554,12.9754384 Z"></path>
                                    </svg>
                                    Talk to Neo
                                </div>
                                <div class="hidden lg:flex items-center justify-center antialiased lg:ml-20 pt-1">


                                </div>
                            </div>
                            <div class="hidden md:flex items-center justify-center">
                                {/*<a href="#" class="mr-5 text-lg font-medium text-true-gray-800 hover:text-cool-gray-700 transition duration-150 ease-in-out">About</a>*/}
                                <AboutModal></AboutModal>
                                <ContactModal></ContactModal>
                            </div>
                        </div>
                        {/*Main Content Here*/}

                        <div className="bg-white shadow-lg sm:rounded-3xl m-4 p-4 w-">
                            <div className="">
                                <div>
                                    <h2 className="font-semibold text-lg tracking-wide">Context</h2>
                                    <p className='text-sm text-gray-600'>

                                     <textarea
                                         className="description bg-gray-100 w-full h-36 border border-gray-300 outline-none text-2xl"
                                         spellCheck="true"
                                         value={query}
                                         onChange={event => {
                                             setQuery(event.target.value);
                                             event.preventDefault();
                                         }}
                                         onKeyUp={event => {
                                             handleKeyPress(event)
                                         }}
                                         placeholder="Input your context here, click generate, and have GPT-Neo carry on your work">

                                     </textarea>
                                        (You can also hit Shift & Enter)
                                    </p>
                                </div>

                            </div>


                            <button class="mt-6 px-8 py-4 rounded-full font-normal tracking-wide bg-purple-600 text-white outline-none
                            focus:outline-none hover:shadow-lg hover:from-blue-700 transition duration-200 ease-in-out"
                                    onClick={() => fetchData()}
                                    onTouchStart={() => fetchData()}
                            >
                                Generate
                            </button>

                        </div>


                    </div>
                </div>


                {/*    Completions Below Here     */}


            </div>

            {isDefault &&
            <div>

                <Completion text="Generated texts will appear here" number="-1"></Completion>
            </div>
            }

            {isError &&

            <div className="flex items-center justify-start pt-6 pl-6">
                <div className="relative w-full max-w-full lg:max-w-6xl xl:max-w-screen-2xl mx-auto mt-8">
                    <p className="text-3xl font-bold text-true-gray-800">Something Went Wrong :( {errorCode}</p>
                </div>

            </div>}

            {isLoading ? (

                <div className="flex items-center place-content-center justify-start pt-6 pl-6">
                    <div className="relative w-full max-w-full lg:max-w-6xl xl:max-w-screen-2xl mx-auto mt-8">
                        <div>
                            <svg className="animate-spin -ml-1 mr-3 h-16 w-16 text-blue-300 inline-flex"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>

                        <div>
                            <p className="text-3xl font-bold text-true-gray-800">Loading ...</p>
                        </div>
                    </div>
                </div>

            ) : (
                <div>
                    {Object.keys(data.completions).map((key, i) => (
                        <Completion text={data.completions[key]} number={i}></Completion>
                    ))}
                </div>
            )}


        </div>


        </body>


    )
}
