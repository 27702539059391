import {useEffect, useState} from "react";
import axios from "axios";



function sample(array) {
  return array[Math.floor(Math.random() * array.length)];
}

const rotations = ['-rotate-1','rotate-1']
const colours = ['red','green','blue','yellow','pink']
const margs = ['-mr-3.5', '-ml-3.5']


function Completion ({text, number}) {
    var col1 = sample(colours)
    var col2 = sample(colours)
    var rot = sample(rotations)
    var marg = sample(margs)

    return (
        <div className="relative w-full max-w-full lg:max-w-6xl xl:max-w-screen-2xl mx-auto mt-8">
          <div
              className={`absolute inset-0 ${marg} bg-gradient-to-r from-${col1}-300 to-${col2}-300 shadow-lg transform sm:skew-y-0 sm:${rot} sm:rounded-3xl bg-opacity-40`}></div>
            <div className="relative bg-white shadow-xl sm:rounded-3xl">
              <div className='container'>
                    <div className="max-w w-full rounded-lg p-4 min-h-100">
                        <h1 className="font-semibold text-2xl tracking-wide"> Completion {parseInt(number) + 1}</h1>
                        <div className="bg-gray-100 sm:rounded min-h-16 p-1">
                            <p className="font-sans bg-grey-50 sm:rounded mt-4 text-2xl" style={{"white-space":'pre-line'}}>{text}</p>
                        </div>
                    </div>
              </div>

            </div>
        </div>

    )
};



const GetGeneration = () => {
  const [data, setData] = useState({ hits: [] });
  const [url, setUrl] = useState(
    'https://hn.algolia.com/api/v1/search?query=redux',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await axios(url);

        setData(result.data);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [url]);

  return [{ data, isLoading, isError }, setUrl];

}

const classestokeep = [
    'from-red-300','from-green-300','from-blue-300','from-yellow-300','from-pink-300',
    'to-red-300','to-green-300','to-blue-300','to-yellow-300','to-pink-300',
    'sm:-rotate-1','sm:rotate-1'
]

export {GetGeneration, Completion};